import React from 'react';
import { Datagrid, List as RaList, ListProps, TextField } from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import Title from '@src/components/Title';
import { perPage } from '@src/configuration';

const List: React.FC<ListProps> = (props) => (
  <RaList
    {...props}
    pagination={<Pagination />}
    perPage={perPage}
    sort={{ field: 'registered_at', order: 'DESC' }}
    title={<Title {...props} type="list" />}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="phone" />
      <LocaleDateField source="registered_at" showTime />
    </Datagrid>
  </RaList>
);

export default List;
