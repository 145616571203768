import React from 'react';
import { Title, useLocale, useSetLocale, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { themeKey } from '@src/configuration';
import { changeTheme } from '@src/store/theme/actions';
import { AppState } from '@src/store/types';

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
});

const Configuration = () => {
  const translate = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  // @ts-ignore
  const theme = useSelector<AppState>((state) => state.theme.name);
  const dispatch = useDispatch();

  return (
    <Card>
      <Title title={translate('ra.configuration')} />
      <CardContent>
        <Typography variant="body1" className={classes.label}>
          {translate('ra.theme.name')}
        </Typography>
        <Button
          variant="contained"
          disabled
          className={classes.button}
          color={theme === 'light' ? 'primary' : 'default'}
          onClick={() => {
            const theme = 'light';
            dispatch(changeTheme(theme));
            localStorage.setItem(themeKey, theme);
          }}
        >
          {translate('ra.theme.light')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'dark' ? 'primary' : 'default'}
          onClick={() => {
            const theme = 'dark';
            dispatch(changeTheme(theme));
            localStorage.setItem(themeKey, theme);
          }}
        >
          {translate('ra.theme.dark')}
        </Button>
      </CardContent>
      <CardContent>
        <Typography variant="body1" className={classes.label}>
          {translate('ra.language')}
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === 'en' ? 'primary' : 'default'}
          onClick={() => setLocale('en')}
        >
          English
        </Button>
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  className={classes.button}*/}
        {/*  color={locale === 'fa' ? 'primary' : 'default'}*/}
        {/*  onClick={() => setLocale('fa')}*/}
        {/*>*/}
        {/*  فارسی*/}
        {/*</Button>*/}
      </CardContent>
    </Card>
  );
};

export default Configuration;
