import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

const factor = 8;

const Theme = (language = 'fa', rtl = true): ThemeOptions => {
  const direction = rtl ? 'rtl' : 'ltr';
  const fontFamily =
    language === 'fa' ? 'IRANSansFaNum, sans-serif' : 'IRANSans, sans-serif';
  document.getElementsByTagName('html')[0].setAttribute('dir', direction);
  return {
    direction,
    typography: {
      fontFamily,
    },
    shape: {
      borderRadius: 10,
    },
    palette: {
      secondary: {
        main: '#4788B6',
      },
      error: {
        main: '#F44336',
      },
      warning: {
        main: '#FF9800',
      },
      info: {
        main: '#2196F3',
      },
      success: {
        main: '#4CAF50',
      },
    },
    overrides: {
      MuiPaper: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
      MuiButton: {
        contained: {
          boxShadow: 'none',
        },
      },
      MuiAppBar: {
        root: {
          border: 0,
        },
      },
      // @ts-ignore
      RaTabbedShowLayout: {
        content: {
          paddingTop: factor * 2,
          paddingBottom: factor * 2,
        },
      },
    },
  };
};

export default Theme;
