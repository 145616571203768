import React from 'react';
import {
  getFieldLabelTranslationArgs,
  Tab,
  useResourceContext,
  useTranslate,
} from 'react-admin';

type Props = Omit<React.ComponentProps<typeof Tab>, 'label'> & {
  label?: string;
};

const SourceTab: React.FC<Props> = ({ path, children, ...rest }) => {
  const resourceContext = useResourceContext();
  const translate = useTranslate();

  return (
    <Tab
      {...rest}
      label={
        path
          ? translate(
              ...getFieldLabelTranslationArgs({
                resource: resourceContext,
                label: rest.label,
                source: path,
              })
            )
          : 'common.tabs.details'
      }
      path={path}
    >
      {children}
    </Tab>
  );
};

export default SourceTab;
