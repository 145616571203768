export const __DEV__ = process.env.NODE_ENV === 'development';

export const perPage = 50;
export const defaultSort = { field: 'created_at', order: 'DESC' };

export const defaultLanguage = 'en';
export const defaultTheme = 'dark';
export const defaultExpires = 120000;

export const languageKey = 'locale';
export const themeKey = 'theme';
