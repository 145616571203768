import React from 'react';
import {
  BooleanField,
  Show as RaShow,
  ShowProps,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import SourceTab from '@src/components/SourceTab';
import Title from '@src/components/Title';

const Show: React.FC<ShowProps> = (props) => (
  <RaShow {...props} title={<Title {...props} type="show" source="phone" />}>
    <TabbedShowLayout>
      <SourceTab>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="phone" />
        <BooleanField source="active" />
        <BooleanField source="phone_confirmed" />
        <LocaleDateField source="registered_at" />
        <LocaleDateField source="updated_at" />
      </SourceTab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
