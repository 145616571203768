import { merge } from 'lodash';
import messages from 'ra-language-english';

import * as common from './common';

export default merge(messages, {
  ra: {
    input: {
      choose_image: 'Choose image',
      choose_file: 'Choose file',
    },
    locales: {
      fa: 'Farsi',
      ar: 'Arabic',
      en: 'English',
    },
    title: 'Unco',
    auth: {
      sign_up: 'Register',
      email: 'Email',
      name: 'Name',
      profile: 'Profile',
      user_menu: 'Account',
    },
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      welcome: {
        title: 'Welcome to Unco Dashboard',
        description: 'Here you can manage everything!!!',
        web_button: 'Website',
      },
    },
    copied: 'Copied!',
  },
  common: {
    menu: {
      library: 'Library',
      meta: 'Meta',
      finance: 'Finance',
      exam: 'Exam',
    },
    tabs: {
      wallet: 'Wallet',
      details: 'Details',
      content: 'Content',
    },
  },
  resources: {
    users: {
      name: 'User |||| Users',
    },
    tickets: {
      name: 'Ticket |||| Tickets',
    },
    ticket_replies: {
      name: 'Ticket |||| Tickets',
    },
    books: {
      name: 'Book |||| Books',
      fields: {
        title_translations: common.title,
        description_translations: common.description,
      },
    },
    authors: {
      name: 'Author |||| Authors',
      fields: {
        name_translations: common.name,
        description_translations: common.description,
      },
    },
    publishers: {
      name: 'Publisher |||| Publishers',
      fields: {
        name_translations: common.name,
        description_translations: common.description,
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        title_translations: common.title,
        description_translations: common.description,
      },
    },
    countries: {
      name: 'Country |||| Countries',
      fields: {
        title_translations: common.name,
      },
    },
    cities: {
      name: 'City |||| Cities',
      fields: {
        title_translations: common.name,
      },
    },
    pages: {
      name: 'Page |||| Pages',
      fields: {
        title_translations: common.title,
        content_translations: common.description,
      },
    },
    slide_shows: {
      name: 'Slideshow |||| Slideshows',
      fields: {
        title_translations: common.title,
        content_translations: common.description,
      },
    },
  },
});
