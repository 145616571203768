import React from 'react';
import { Admin, Resource } from 'react-admin';
import CssBaseline from '@material-ui/core/CssBaseline';

// import authProvider from '@src/authentication';
import Dashboard from '@src/components/Dashboard';
import Layout from '@src/components/Layout';
import Login from '@src/components/Login';
import RTL from '@src/components/RTL';
import { useDataProvider } from '@src/data';
import history from '@src/history';
import i18nProvider from '@src/i18n';
import * as resources from '@src/resources';
import customRoutes from '@src/routes';
import customReducers from '@src/store';

const App = () => {
  const dataProvider = useDataProvider();

  // TODO add proper loading component
  if (!dataProvider) return null;

  return (
    <>
      <CssBaseline />
      <RTL>
        <Admin
          history={history}
          title={process.env.REACT_APP_NAME}
          layout={Layout}
          dashboard={Dashboard}
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          // authProvider={authProvider}
          loginPage={Login}
          customReducers={customReducers}
          customRoutes={customRoutes}
        >
          <Resource {...resources.User} />
        </Admin>
      </RTL>
    </>
  );
};

export default App;
