import React from 'react';
import ApolloClient from 'apollo-boost';
import buildHasuraProvider, { buildFields } from 'ra-data-hasura';

import * as jwtManager from '@src/authentication/jwtManager';

const client = new ApolloClient({
  uri: process.env.REACT_APP_HASURA_URL,
  request: (operation) => {
    const token = jwtManager.getAccessToken();
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      operation.setContext({
        headers: {
          'x-hasura-admin-secret': 'hasura',
        },
      });
    }
  },
});

const customBuildFields = (type, fetchType) => {
  // const defaultFields = buildFields(type, fetchType);
  // if (fetchType === 'UPDATE') {
  //   console.log(defaultFields);
  // }
  return buildFields(type, fetchType);
};

const useDataProvider = () => {
  const [dataProvider, setDataProvider] = React.useState(null);

  React.useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider(
        {
          client,
        },
        { buildFields: customBuildFields }
      );
      setDataProvider(() => dataProvider);
    };
    buildDataProvider().catch(console.error);
  }, []);

  return dataProvider;
};

export default useDataProvider;
