import React from 'react';
import { Layout } from 'react-admin';
import CssBaseline from '@material-ui/core/CssBaseline';

import useTheme from '@src/hooks/useTheme';

import AppBar from './AppBar';

const CustomLayout: React.FC = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <>
      <Layout {...props} appBar={AppBar} theme={theme}>
        <CssBaseline />
        {children}
      </Layout>
    </>
  );
};

export default CustomLayout;
