import React from 'react';

const Logo: React.FC = () => {
  return (
    <img
      width={82}
      height={35}
      src={require('@src/assets/images/logo.svg')}
      alt="Logo"
    />
  );
};

export default React.memo(Logo);
