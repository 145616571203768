import React from 'react';
import {
  Identifier,
  Title as RaTitle,
  useGetResourceLabel,
  useLocale,
  useTranslate,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import { get, isEmpty } from 'lodash';

import { defaultLanguage } from '@src/configuration';

type Props = {
  source?: string;
  type: 'list' | 'show' | 'create' | 'edit';
  resource?: any;
  id?: Identifier;
  record?: any;
  translatable?: boolean;
};

const Title: React.FC<Props> = ({
  type,
  resource,
  id,
  record,
  source = 'id',
  translatable,
}) => {
  const translate = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const locale = useLocale();
  const final_locale = React.useMemo(() => {
    if (isEmpty(get(record, `${source}.${locale}`))) {
      return defaultLanguage;
    }
    return locale;
  }, [locale, record, source]);

  const title = React.useMemo(() => {
    switch (type) {
      case 'create':
        return translate(`ra.page.${type}`, {
          name: getResourceLabel(resource, 1),
        });
      case 'edit':
      case 'show': {
        let newId = get(record, source, id);
        if (newId && newId !== id) {
          if (translatable) {
            newId = get(newId, final_locale);
          }
          return translate(`ra.page.${type}`, {
            name: getResourceLabel(resource, 1),
            id: newId,
            record,
          }).replace('#', '');
        }
        return translate(`ra.page.${type}`, {
          name: getResourceLabel(resource, 1),
          id,
          record,
        });
      }
      case 'list':
        return translate(`ra.page.${type}`, {
          name: getResourceLabel(resource, 2),
        });
    }
  }, [
    final_locale,
    getResourceLabel,
    id,
    record,
    resource,
    source,
    translatable,
    translate,
    type,
  ]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <RaTitle title={title} />
    </>
  );
};

export default Title;
