import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import merge from 'lodash/merge';

import baseTheme from './base';

const Theme = (language = 'fa', rtl = true): ThemeOptions => {
  return merge(baseTheme(language, rtl), {
    palette: {
      type: 'dark',
      primary: {
        main: '#0063F7',
        dark: '#00245Ai',
        light: '#3385FF',
        contrastText: '#fff',
      },
      background: {
        default: '#1C1C28',
        paper: '#28293D',
      },
      text: {
        primary: '#fff',
        secondary: '#606191',
      },
    },
  } as ThemeOptions);
};

export default Theme;
